<template>
  <div class="page">
    <!--pages/bonuslist/bonuslist.wxml-->
    <div class="head">
      <div class="left">
        <div>待结佣金（元）</div>
        <div class="total">{{spread_amount}}</div>
      </div>
      <img class="tg-img" mode="widthFix" src="~images/yjjl.png">
    </div>
  
    <div class="list">
      <div class="item flexcenter" v-for="(item, idx) in bounsList" :key="item.id">
        <div class="flexcenter">
          <img class="shape-img" mode="widthFix" src="~images/bonus-shape.png">
          <div class="desc">
            <div class="title">佣金</div>
            <div class="time">{{item.add_time_text}}</div>
          </div>
        </div>
        <div class="num">+{{item.spread_amount}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bonuslist',
    data() {
      return {
        page:0,
        totalPage:0,
        totalNumber:0,
        bounsList:[],
        spread_amount:0,
      }
    },
    created() {
      this.relateOrderList(1,true);
      this.getDetail();
    },
    methods: {
      /**
       * 获取页面数据
       */
      relateOrderList(pageNo, override){
        this.post(
          this.API.relateOrderList,
          {pageNo:pageNo,pageSize:20},
        ).then(response => {
          let bounsList = response.data.orders.data;
          this.page = response.data.orders.current_page     //当前的页号
          this.totalPage = response.data.orders.last_page    //总页数
          this.totalNumber =response.data.orders.total //总条数
          this.bounsList = override ? bounsList : this.bounsList.concat(bounsList)

        })
      },
      /**
       * 获取推广员信息
       */
      getDetail(){
        let that = this;
        this.post(
          this.API.spreadDetail,{},
        ).then(response => {
          if(response.data.err=='1') {
            this.$toast(response.data.msg);
            setTimeout(function(){
              this.$router.back()
            },1000)
          } else {
            this.spread_amount = response.data.detail.spread_amount
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  .head {
    position: relative;
    padding: 32px;
    background-color: #F8D414;
  }
  .head .left {
    font-size: 24px;
  }
  .head .left .total {
    font-size: 56px;
    font-weight: bold;
  }
  .head .tg-img {
    position: absolute;
    right: 0;
    bottom: -28px;
    width: 141px;
  }

  .list .item {
    position: relative;
    justify-content: space-between;
    padding: 30px;
    background-color: #FFFFFF;
  }
  .list .item:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90%;
    height: 1px;
    content: "";
    border-bottom: 2px solid #EEEEEE;
  }
  .list .item:last-child:after {
    border: 0;
  }
  .list .item .shape-img {
    margin-right: 30px;
    width: 90px;
  }
  .list .item .desc .title {
    font-size: 32px;
  }
  .list .item .desc .time {
    font-size: 24px;
    color: #959595;
  }
  .list .item .num {
    font-size: 36px;
    font-weight: bold;
  }

</style>
